<template>
  <div class="content_box">
    <div class="content_head flex">
      <div class="flex_1">
        <slot name="titleLeft"></slot>
      </div>
      <div class="content_title" v-if="title">
        {{title}}
      </div>
      <div class="flex_1">
        <slot name="titleRight"></slot>
      </div>
    </div>
    <div class="content_body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'contentBox',
  props: {
    title: {
      type: String,
      default: ''
    },
  }
}
</script>

<style lang="scss" scoped>
.content_box{
  border-radius: 10px;
  border: 1px solid rgba($color: $themeColor, $alpha: 1.0);
  box-shadow: 0 0 30px 0 rgba($color: $themeColor, $alpha: 0.8) inset;
  padding:0 1.2vw;
  background: rgba($color: #6d1e12, $alpha: 0.4);
  .content_title{
    min-width: 7.3vw;
    padding: 0 1vw;
    height: 2.19vw;
    line-height: 2.19vw;
    overflow: hidden;
    background: url(~static/images/titleBg1.png) no-repeat;
    background-size: 100% 100%;
    text-align: center;
    color: #fff;
    font-size: 1vw;
    font-weight: bold;
  }
}

</style>