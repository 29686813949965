<template>
  <div class="swiper">
    <div class="swiper-wrapper">
      <slot></slot>
    </div>
    <slot name="swiper-components"></slot>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import  swiper,{ Navigation,Pagination, Thumbs, Autoplay,Grid }  from 'swiper';
swiper.use([Navigation,Pagination,Thumbs,Autoplay,Grid]); 
import 'swiper/css';
import 'swiper/css/bundle';

const swiperDefult = {
  slidesPerView: 1
}
export default {
  name:'swiperView',
  data(){
    return {
      swiper: null,
    }
  },
  props: {
    options: {
      type: Object,
      default: () => swiperDefult
    }
  },
  mounted(){
    this.swiper = new swiper(this.$el,{
      on: {
        slideChangeTransitionStart: () => {
          this.$emit('changeStart',this.swiper);
        },
        slideChangeTransitionEnd: () => {
          this.$emit('changeEnd',this.swiper);
        },
        click: (e) => {
          this.$emit('slideClick',e);
        },
      },
      ...this.options,
    });
    this.$emit('swiper',this.swiper);
  },
  methods: {
    
  }
}
</script>

<style lang="scss" scoped>
// @import url(swiper/swiper-bundle.min.css);
</style>