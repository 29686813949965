<template>
  <div class="column_switch">
    <div class="column_icon">
      <div class="icon1">
        <img src="~static/icon/columnIcon1.png" />
      </div>
      <div class="iconBg"></div>
    </div>
    <div class="column_text">智慧橙村</div>
    <div class="column_list">
      <div class="arrow">
        <i class="zicon zw-arrow-right-bold"></i>
      </div>
      <div :class="['item', $route.path.indexOf(item.path) !== -1 ? 'active' :'']" v-for="item in columnList" :key="item.title">
        <a :href="item.path">
          <div class="icon">
            <img src="~static/icon/columnIcon3.png" />
          </div>
          <div class="text">{{item.title}}</div>
        </a>
      </div>
      <div class="item active">
        <a>
          <div class="icon">
            <img src="~static/icon/columnIcon3.png" />
          </div>
          <div class="text">智慧党建</div>
        </a>
      </div>
      <div class="item">
        <a href="https://zhili.zhihuichengcun.com/agricultural">
          <div class="icon">
            <img src="~static/icon/columnIcon3.png" />
          </div>
          <div class="text">农产品<br/>全程管理</div>
        </a>
      </div>
      <div class="item">
        <a href="javascript:;">
          <div class="icon">
            <img src="~static/icon/columnIcon3.png" />
          </div>
          <div class="text">环境监测</div>
        </a>
      </div>
      <div class="item">
        <a href="javascript:;">
          <div class="icon">
            <img src="~static/icon/columnIcon3.png" />
          </div>
          <div class="text">应急<br/>（危险源）</div>
        </a>
      </div>
      <div class="item">
        <a href="https://zhili.zhihuichengcun.com/mall">
          <div class="icon">
            <img src="~static/icon/columnIcon3.png" />
          </div>
          <div class="text">智慧旅游</div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'commonView',
  data() {
    return {
      columnList: [
        {title:'乡村宜居',path:'https://zhili.zhihuichengcun.com/life'},
        {title:'乡村惠民',path:'https://zhili.zhihuichengcun.com/benefit'},
        {title:'乡村文明',path:'https://zhili.zhihuichengcun.com/culture'},
        {title:'乡村治理',path:'https://zhili.zhihuichengcun.com/govern'},
      ],
      currentColumn: '乡村宜居',
    }
  },
  watch: {
    // '$route' (to) {
    //   this.columnList.forEach(item => {
    //     if (to.path.indexOf(item.path) !== -1) {
    //       this.currentColumn = item.title
    //     }
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
.column_switch{
  position: fixed;
  left: 2vw;
  bottom: 1vw;
  z-index: 99;
  .column_icon{
    width: 5vw;
    height: 5vw;
    position: relative;
    cursor: pointer;
    .icon1{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-52%,-44%);
      width: 3.2vw;
      height: 3.2vw;
      z-index: 9;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    @keyframes rotating{
      0%{
        transform: rotate(0deg);
      }
      50%{
        transform: rotate(180deg);
      }
      100%{
        transform: rotate(0deg);
      }
    }
    .iconBg{
      width: 100%;
      height: 100%;
      background: url(~static/icon/columnBg1.png) no-repeat center center;
      background-size: contain;
      animation: rotating 10s linear infinite;
    }
    
  }
  .column_text{
    color: #fff;
    padding-top: 0.5vw;
    text-align: center;
    text-shadow: 0  0 3px #fff;
  }
  .column_list{
    position: absolute;
    left: 120%;
    top: 0;
    right: -91vw;
    display: flex;
    background: #4b1108;
    border: 1px solid $themeColor2;
    padding: 1vw;
    transform: translateX(10px);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    opacity: 1;
    visibility: visible;
    transform: translate(0);
    .arrow{
      position: absolute;
      width: 1vw;
      height: 1vw;
      line-height: 1vw;
      color: $themeColor2;
      top:50%;
      left: -1vw;
      text-align: right;
      transform: translateY(-50%);
      font-size: 1.2vw;
    }
    &::before,
    &::after{
      content: '';
      position: absolute;
      width: 3vw;
      height: 3vw;
      
    }
    &::before{
      left: 0;
      top: 0;
      border-left: 3px solid $themeColor2;
      border-top: 3px solid $themeColor2;
    }
    &::after{
      right: 0;
      bottom: 0;
      border-right: 3px solid $themeColor2;
      border-bottom: 3px solid $themeColor2;
    }
    .item{
      position: relative;
      z-index: 9;
      flex: 1;
      .icon{
        width: 3vw;
        height: 3vw;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 300ms;
        img{
          width: 70%;
          height: 70%;
        }
      }
      .text{
        font-size: 0.8vw;
        color: $themeColor2;
        padding-top: 0.3vw;
        text-align: center;
      }
      &.active{
        .icon{
          background: url(~static/icon/columnIcon2.png) no-repeat center center;
          background-size: contain;
        }
      }
      &:hover{
        .icon{
          transform: translateY(-5px);
        }
      }
    }
    
  }
  &:hover{
    .column_list{
      opacity: 1;
      visibility: visible;
      transform: translate(0);
    }
  }
}
</style>