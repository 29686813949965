<template>
  <header>
    <div class="header_date">
      <div class="time">{{nowDate}} {{ time }}</div>
      <div class="week">星期{{ week }}</div>
    </div>
    <!-- <div class="header_title">智慧橙村-乡村治理数字化服务平台</div> -->
    <div class="header_title">{{siteTitle}}</div>
    <div class="header_weater">当前天气：{{weater}}</div>
    <div class="header_back"><a href="https://zhili.zhihuichengcun.com/"><i class="zicon zw-back"></i> 返回</a> </div>
    <div class="header_nav">
      <div 
        :class="['item', $route.path === item.path ? 'active' : '']" 
        v-for="item in $store.state.routeList" 
        :key="item.path">
        <router-link :to="item.path">{{item.name}}</router-link>
      </div>
    </div>
  </header>
</template>

<script>
import { tianqi } from 'network/api'
import { common } from "assets/common/mixins";

export default {
  name: 'pageHeader',
  mixins:[common],
  data() {
    return {
      date: new Date(),
      weater:'',
      siteTitle:'乡村智慧党建平台'
    }
  },
  created() {
    const second = this.date.getSeconds()
    const timeout = (60 - second) * 1000
    setTimeout(() => {
      this.date = new Date()
      setInterval(() => {
        this.date = new Date()
      }, 60000)
    }, timeout)
    
    
    tianqi({city:'奉节'}).then(res => {
      if (res.code === '0000') {
        this.weater = res.data.data.forecast[0].type
      } else {
        this.weater = '未知'
      }
    })
    
  },
}
</script>

<style lang="scss" scoped>
header {
  height: 7vw;
  background: url(~static/images/headerBg.png) top center no-repeat;
  background-size: 100%;
  position: relative;
  color: #fff;
  .header_date {
    position: absolute;
    left: 13.9vw;
    top: 2.5vw;
    font-size: 0.9vw;
    .week {
      padding-left: 8.5vw;
    }
  }
  .header_title {
    text-align: center;
    padding-top: 0.3vw;
    font-size: 2.6vw;
    font-weight: bold;
  }
  .header_weater{
    position: absolute;
    right: 13.9vw;
    top: 2.5vw;
    font-size: 0.9vw;
  }
  .header_back{
    position: absolute;
    right: 1vw;
    top: 3.5vw;
    font-size: 0.9vw;
    border-radius: 0.2vw;
    border: 1px solid $themeColor;
    line-height: 1.5vw;
    padding: 0 0.4vw;
    cursor: pointer;
    font-size: 0.8vw;
  }
  .header_nav{
    color: #fff;
    text-align: center;
    font-size: 0;
    padding-top: 1.3vw;
    .item{
      display: inline-block;
      font-size: 0.8vw;
      margin: 0 3vw;
      position: relative;
      
      height: 2vw;
      line-height: 2vw;
      &~.item{
        &::after{
          content: '';
          position: absolute;
          left: -5vw;
          top: 50%;
          width: 4vw;
          border-bottom: 3px solid $themeColor;
          transform: translateY(-50%);
        }
      }
      &.active{
        background: url(~static/images/navBg1.png) center center no-repeat;
        background-size: 100% 100%;
      }
      a{
        color: #fff;
        display: block;
        padding: 0 1vw;
      }
    }
  }
}
</style>