import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Home,
    redirect:'/party',
    children:[
      {
        path: '/party',
        name: 'party',
        component: () => import(/* webpackChunkName: "education" */ 'views/party/Party'),
        redirect:'/party/portal',
        children:[
          {
            path: '/party/portal',
            name: 'portal',
            component: () => import(/* webpackChunkName: "education" */ 'views/party/Portal')
          },
          {
            path: '/party/education',
            name: 'education',
            component: () => import(/* webpackChunkName: "education" */ 'views/party/Education')
          },
          {
            path: '/party/construction',
            name: 'construction',
            component: () => import(/* webpackChunkName: "education" */ 'views/party/Construction')
          },
          
        ]
      },
    ]
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
