import { axios } from './request'

export function get(parameter) {
  return axios({
    url: parameter.url || parameter,
    method: 'GET',
    params: parameter.data || {}
  })
}

export function get_data(parameter) {
  return axios({
    method: 'GET',
    params: {act:'get_data',...parameter}
  })
}
// ------文章-------  //

// 文章列表
// class_id:文章分类ID
// user_id:会员ID(可选)
// key:关键词(可选,用于筛选)
// page:页数0全部
// size:返回条数0全部
export function article_list(parameter) {
  return axios({
    method: 'GET',
    params: {act:'article_list',...parameter}
  })
}
// 文章详情
// id:文章id
export function get_article(parameter) {
  return axios({
    method: 'GET',
    params: {act:'get_article',...parameter}
  })
}
// 文章分类
// class_id:45
export function article_class(parameter) {
  return axios({
    method: 'GET',
    params: {act:'article_class',...parameter}
  })
}
// 获得天气数据
// city:'成都'
export function tianqi(parameter) {
  return axios({
    method: 'GET',
    params: {act:'tianqi',...parameter}
  })
}
// 党员学习排行
export function study_list(parameter) {
  return axios({
    method: 'GET',
    params: {act:'study_list',...parameter}
  })
}
// 党建资讯统计
export function dangjianzixun_data(parameter) {
  return axios({
    method: 'GET',
    params: {act:'dangjianzixun_data',...parameter}
  })
}
// 党员信息统计
export function dangyuanxinxi_data(parameter) {
  return axios({
    method: 'GET',
    params: {act:'dangyuanxinxi_data',...parameter}
  })
}
// 发展党员统计
export function fazhandangyuan_data(parameter) {
  return axios({
    method: 'GET',
    params: {act:'fazhandangyuan_data',...parameter}
  })
}
// 党员列表
export function users_list(parameter) {
  return axios({
    method: 'GET',
    params: {act:'users_list',...parameter}
  })
}
// 党费缴纳统计
export function dangfeijiaona_data(parameter) {
  return axios({
    method: 'GET',
    params: {act:'dangfeijiaona_data',...parameter}
  })
}
// 党员学习统计
export function dangyuanxuexi_data(parameter) {
  return axios({
    method: 'GET',
    params: {act:'dangyuanxuexi_data',...parameter}
  })
}


